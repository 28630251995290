import React from 'react';
import cls from "./about.module.scss"
import Header from '../../components/about-components/header';
import Article from '../../components/about-components/article';
import Description from '../../components/about-components/description';
import Social from '../../components/about-components/social';

const About = () => {

  return (
    <>
      <div className={cls.about_container}>
        <Header />
        <Article />
        <Description />
        {/* <Social /> */}
      </div>
    </>
  );
}

export default About;
