import { t } from "i18next";
import React, { useState } from "react";


import { ReactComponent as DownArrowIcon } from "src/assets/ArrowDown.svg";

import styles from "./styles.module.scss";
import OpenByHeight from "src/shared/components/open-by-height";

const ShowDetailedWrap = ({ children }) => {
  const [isShowDetailed, setIsShowDetailed] = useState(false);
  return (
    <div>
      <button className={styles.showDetailedButton} onClick={() => setIsShowDetailed(!isShowDetailed)}>
        {isShowDetailed ? t("hide") : t("show_more")}
        <DownArrowIcon
          className={`${styles.showDetailedArrow} ${isShowDetailed ? styles.showDetailedArrowActive : ""}`}
        />
      </button>

      <OpenByHeight isOpen={isShowDetailed}>{children}</OpenByHeight>
    </div>
  );
};

export default ShowDetailedWrap;
