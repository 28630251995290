import axios from "axios";
import Cookies from "js-cookie";
import { apiCountry } from "../config";

export const getCountry = async (changeLanguage, changeTheme, setLoading) => {
  const setCountryFromApi = (countryCode) => {
    Cookies.set("country", countryCode, { expires: 30 });
    changeTheme(countryCode);
    setLoading(false);
  };

  const setLanguageFromApi = (language) => {
    if (!Cookies.get("language_name")) {
      Cookies.set("language_name", language, { expires: 30 });
      changeLanguage(language);
    }
    setLoading(false);
  };
  try {
    const response = await axios.get(apiCountry);
    if (response.data.country_code == null) {
      setCountryFromApi("EN");
      return setLanguageFromApi("EN");
    }
    const country = response.data.country_code;
    switch (country) {
      case "UA":
        setCountryFromApi("UA");
        setLanguageFromApi("UA");
        break;
      case "SK":
        setCountryFromApi("SK");
        setLanguageFromApi("SK");
        break;
      default:
        setCountryFromApi("EN");
        setLanguageFromApi("EN");
    }
  } catch (error) {
    console.error(error);
    setCountryFromApi("EN");
    setLanguageFromApi("EN");
  }
};
