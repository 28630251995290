import { memo } from "react";
import { ReactComponent as CarmaLogo } from "src/assets/CarmaLogo.svg";
import { AppBar, Box, Button, Container, Toolbar } from "@mui/material";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import CookieBar from "src/components/cookie-bar";
import { t } from "i18next";

const PartnerLayout = ({ partnerTokens, handleExit }) => {
  const isAuth = partnerTokens.accessToken && partnerTokens.mainApiAccessToken;

  return (
    <AppBar position="static">
      <CookieBar />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/partner">
            <CarmaLogo className={styles.logo} />
          </Link>
          <Box marginLeft="auto">
            {isAuth && (
              <>
                <Button sx={{ mr: "10px" }} color="success" variant="contained">
                  <Link to="/partner/create-driver">{t("add_driver")}</Link>
                </Button>
                <Button onClick={handleExit} color="error" variant="contained">
                  {t("logout")}
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default memo(PartnerLayout);
