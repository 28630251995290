import React from "react";
import cls from "./footer.module.scss";
import { Link } from "../../../helper/Link";
import { useTranslation } from "react-i18next";
import { ReactComponent as CarmaLogo } from "../../../assets/CarmaLogo.svg";
import AppStoreLabel from "../../../assets/AppStoreLabel.svg";
import PlayMarketLabel from "../../../assets/PlayMarketLabel.svg";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const navigation = [
    { name: t("for_passengers"), path: "/passenger" },
    { name: t("for_drivers"), path: "/driver" },
    // { name: t('tariffs'), path: '/tariffs' },
    { name: t("about_company"), path: "/about" },
  ];

  const currentPath = location.pathname;

  return (
    <div className={cls.footer_container}>
      <div className={cls.menu_block}>
        <Link to="/" className={cls.logo}>
          <CarmaLogo />
        </Link>
        <div className={cls.links_block}>
          {navigation.map((navigation) => (
            <div
              className={`${cls.link} ${currentPath === navigation.path ? cls.active_link : ""}`}
              key={navigation.name}
            >
              <Link to={navigation.path}>{navigation.name}</Link>
            </div>
          ))}
        </div>
      </div>
      <div className={cls.donwload_block}>
        <div className={cls.items_container}>
          <div className={cls.donwload_item}>
            <p>{t("ordered_trip")}</p>
            <div className={cls.donwload_links}>
              <a href="https://apps.apple.com/ua/app/carma-taxi/id6465251519?l=ru">
                <img src={AppStoreLabel} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.carmataxi.client">
                <img src={PlayMarketLabel} alt="" />
              </a>
            </div>
          </div>
          <div className={cls.donwload_item}>
            <p>{t("join_us")}</p>
            <div className={cls.donwload_links}>
              <a href="https://apps.apple.com/ua/app/carma-driver/id6450720893?l=ru">
                <img src={AppStoreLabel} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.carmataxi.driver">
                <img src={PlayMarketLabel} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={cls.copy_policy}>
        <p>© 2024 Copyright</p>
      </div>
    </div>
  );
};

export default Footer;
