import { CircularProgress, Grid } from "@mui/material";
import React from "react";

const CircularLoader = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100%"
      mt={10}
    >
      <CircularProgress />
    </Grid>
  );
};

export default CircularLoader;
