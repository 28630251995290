import React from 'react';
import cls from "./passenger.module.scss"
import Bracket from '../../components/passenger-components/bracket';
import Advantages from '../../components/passenger-components/advantages';
import Pairs from '../../components/passenger-components/pairs';

const Passenger = () => {

  return (
    <>
      <div className={cls.passenger_container}>
        <Bracket />
        <Advantages />
        <Pairs />
      </div>
    </>
  );
}

export default Passenger;
