export const handleApprove = async (id, setStatus, chgInspectionStatus, dispatch) => {
    console.log(id)
    const inspectionInfo = {
        id: id,
        status: 1,
    }
    await dispatch(chgInspectionStatus(inspectionInfo))
    setStatus(1)
};

export const handleReject = async (id, setStatus, chgInspectionStatus, dispatch) => {
    const inspectionInfo = {
        id: id,
        status: 2,
    }
    await dispatch(chgInspectionStatus(inspectionInfo))
    setStatus(2)
};