import { rtkApi } from "src/shared/api/rtkApi";
import { mainBackendApi } from "src/shared/config";

export const driverSalaryQuery = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getDriverSalaryData: builder.query({
      query: ({ driverId, from, to }) => ({
        url: `${mainBackendApi}/partner/v1/drivers/${driverId}/salary/search`,
        method: "POST",
        body: { from, to },
      }),
    }),
  }),
});

export const { useGetDriverSalaryDataQuery } = driverSalaryQuery;
