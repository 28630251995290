import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { StoreProvider } from "src/shared/store/StoreProvider/ui/StoreProvider";
import i18n from "./i18n/config";
import "./styles/root.scss";

const root = createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
    <StoreProvider>
      <Router>
        <App />
      </Router>
    </StoreProvider>
  </I18nextProvider>
);
