import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstanceWithMainApiToken } from "src/shared/api/axios.config";
import { apiCreateDriver, apiDocuments } from "src/shared/config";
import { DriverRegistrationStep } from "src/shared/constants/driver";
import { uploadFile } from "../helpers/uploadFile";
import { driverActions } from "./driverSlice";

export const errorCreateDriverMessages = {
  cannot_sign_up_user: "Unable to sign up user. Please try again later.",
  unknown_error: "An unknown error occurred. Please try again later.",
};

export const createDriver = createAsyncThunk("driver/createDriver", async (driverData, thunkAPI) => {
  try {
    const { data } = await axiosInstanceWithMainApiToken.post(apiCreateDriver, driverData);

    const nextStep = data.nextStep;

    thunkAPI.dispatch(driverActions.setNextStep(DriverRegistrationStep[nextStep]));

    return data;
  } catch (error) {
    const errorCode = error.response.data.code;
    return thunkAPI.rejectWithValue(errorCreateDriverMessages[errorCode]);
  }
});

export const fetchDocumentTitles = createAsyncThunk("driver/fetchDocumentTitles", async (_, thunkAPI) => {
  try {
    const { data } = await axiosInstanceWithMainApiToken.get(apiDocuments);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const uploadFiles = createAsyncThunk("driver/uploadFiles", async (fileData, thunkAPI) => {
  try {
    const documentsData = thunkAPI.getState().driver.documentsData;
    const driverData = thunkAPI.getState().driver.driverData;

    const uploadedDocuments = [];

    // Итерируемся по информации о документах
    for (const documentItem of documentsData) {
      // Проверяем наличие файлов для текущего типа документа
      const documentTypeId = documentItem.typeId;
      const currentDocumentFiles = fileData[documentTypeId];

      if (currentDocumentFiles) {
        // Загружаем каждый файл и добавляем информацию в массив
        for (const file of currentDocumentFiles) {
          const uploadResult = await uploadFile(file);

          const uploadedDocumentInfo = {
            typeId: documentTypeId,
            url: uploadResult.data.url,
          };

          // Добавляем информацию в массив загруженных файлов
          uploadedDocuments.push(uploadedDocumentInfo);
        }
      }
    }

    // Формируем окончательные данные для отправки на сервер
    const finalData = {
      userId: driverData.driverId,
      documents: uploadedDocuments,
    };

    const { data } = await axiosInstanceWithMainApiToken.post(apiDocuments, finalData);
    const nextStep = data.nextStep;

    thunkAPI.dispatch(driverActions.setNextStep(DriverRegistrationStep[nextStep]));
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
