import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import { DriverRegistrationStep } from "src/shared/constants/driver";
import { t } from "i18next";

const CompleteStep = ({ driverActions }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(driverActions.setNextStep(DriverRegistrationStep.Profile));
  };

  return (
    <Grid container direction="column" spacing={1.5} mt={3}>
      <Typography align="center" variant="h5">
        {t("registration_successfully_completed")}
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="end" flexDirection="column">
        <Button color="inherit" sx={{ mt: 3, textTransform: "none" }} variant="contained" onClick={handleClick}>
          {t("new_driver")}
        </Button>
      </Box>
    </Grid>
  );
};

export default memo(CompleteStep);
