import React from 'react';
import cls from "./driver.module.scss"
import Invite from '../../components/driver-components/invite';
import Hang from '../../components/driver-components/hang';
import Media from '../../components/driver-components/media';
import Network from '../../components/driver-components/network';

const Driver = () => {

  return (
    <>
      <div className={cls.driver_container}>
        <Invite />
        <Hang />
        <Media />
        {/* <Network /> */}
      </div>
    </>
  );
}

export default Driver;
