export const metersToKm = (meters, options) => {
  const km = meters / 1000;

  if (options) {
    const { calculateLowerNum, calculateHigherNum } = options;

    if (calculateLowerNum && km < calculateLowerNum) {
      return km.toFixed(1);
    }

    if (calculateHigherNum && km >= calculateHigherNum) {
      return Math.round(km);
    }
  }

  return km.toFixed(2);
};
