import React from "react";
import styles from "./styles.module.scss";

const SalaryModulePayments = ({ paymentsItems, currentPayment, setCurrentPayment }) => {
  return (
    <ul className={styles.paymentsItemsWrap}>
      {paymentsItems.map((item, index) => (
        <li
          onClick={() => setCurrentPayment(item)}
          key={`${item.name}_${index}`}
          className={`${currentPayment.name === item.name ? styles.activePayment : ""}`}
        >
          {item.img ? item.img : null}
          {item.name}
        </li>
      ))}
    </ul>
  );
};

export default SalaryModulePayments;
