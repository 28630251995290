import { memo, useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import CountryPhoneSelect from "src/shared/components/country-phone-select";
import { driverDataSchema } from "../../schemas";
import { createDriver } from "src/pages/create-driver/model/driverAsyncActions";
import CustomModal from "src/shared/components/modal";

const initialValues = {
  phone: "",
  email: "",
  firstName: "",
  lastName: "",
};

const getFilteredKeys = () => {
  const { phone, ...filteredValues } = initialValues;
  return Object.keys(filteredValues);
};

const ProfileStep = ({ isLoading, errors, driverActions }) => {
  const [validation, setValidation] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema: driverDataSchema,
    validateOnChange: validation,
    validateOnBlur: validation,
    onSubmit: async (values) => {
      await dispatch(createDriver({ ...values, phone: `+${values.phone}` }));
    },
  });

  const handleFormSubmit = useCallback((event) => {
    setValidation(true);
    formik.handleSubmit(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleFormSubmit}>
      <Box mt={3}>
        {errors && (
          <CustomModal
            title="Error"
            open={Boolean(errors)}
            onClose={() => dispatch(driverActions.clearError())}
          >
            {errors}
          </CustomModal>
        )}
        <CountryPhoneSelect
          formik={formik}
          enableSearch
          value={formik.values.phone}
          error={Boolean(formik.errors.phone)}
          name="phone"
          placeholder={t("phone_number")}
          label=""
        />
      </Box>
      {getFilteredKeys().map((field) => (
        <Box key={field} mt={2}>
          <TextField
            onChange={formik.handleChange}
            value={formik.values[field]}
            error={Boolean(formik.errors[field])}
            helperText={t(formik.errors[field])}
            label={t(field)}
            name={field}
            fullWidth
            required
          />
        </Box>
      ))}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        flexDirection="column"
      >
        <LoadingButton
          sx={{ mt: 3, textTransform: "none" }}
          type="submit"
          loading={isLoading}
          loadingPosition="center"
          color="inherit"
          variant="contained"
        >
          {t("next")}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default memo(ProfileStep);
