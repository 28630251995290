import { useSelector } from "react-redux";
import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";
import { ReactComponent as TechPassportIcon } from "../../assets/TechPassportIcon.svg";
import { ReactComponent as ArrowBackIcon } from "../../assets/ArrowBackIcon.svg";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { getDriverInfo } from "../partner/model/partnerSelectors";
import "./styles.scss"

const Profile = () => {
    const data = useSelector(getDriverInfo);
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    }

    const inputFields = [
        {
            items: [
                { label: t('name'), value: data.firstName },
                { label: t('last_name'), value: data.lastName },
            ]
        },
        {
            items: [
                { label: t('country'), value: data.country },
                { label: t('call_sign'), value: data.callSign },
            ]
        },
        {
            items: [
                { label: t('phone_number'), value: data.phone },
                { label: t('email'), value: data.email },
            ]
        }
    ];

    return (
        <div className='driver_details_page'>
            <div className='driver_main_block'>
                <div className="go_back_btn" onClick={handleGoBack}>
                    <ArrowBackIcon />
                    <p className='go_back_text'>{t('go_back')}</p>
                </div>
                <p className='drivers_data_title'>{t('drivers_data')}</p>
                <div className='drivers_info_block'>
                    <div className='drivers_photo'>
                        {data.photo
                            ? <img src={data.photo} alt='' />
                            :
                            <div className='drivers_photo_none'>
                                <div className='drivers_circle_none_photo'>
                                    <UserIcon />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='drivers_info_grounds'>
                        {inputFields.map((group, index) => (
                            <div className='drivers_info_ground' key={index}>
                                {group.items.map((item, itemIndex) => (
                                    <div className='driver_input pointer_none' key={itemIndex}>
                                        <p><span>*</span> {item.label}</p>
                                        <input value={item.value} />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <p className='drivers_data_title block_with_padding_top'>{t('drivers_license')}</p>
                <div className='driver_license_block block_with_padding_bottom'>
                    <div className='driver_license_single_item'>
                        {data.driverLicense
                            ? <img src={data.driverLicense} alt='' />
                            :
                            <div className='driver_license_block_none'>
                                <div className='driver_license_block_none_icon'>
                                    <TechPassportIcon />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
