import { memo, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, ImageList, ImageListItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { generateBase64ImageUrl } from "src/helper/generateBase64ImageUrl";
import CustomModal from "src/shared/components/modal";
import { fetchDocumentTitles, uploadFiles } from "src/pages/create-driver/model/driverAsyncActions";
import { t } from "i18next";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const DocumentsStep = (props) => {
  const { isLoading, documentsData } = props;

  const [uploadedPhotos, setUploadedPhotos] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);

  const dispatch = useDispatch();

  const shouldDisableBtn = Object.keys(uploadedPhotos).length !== documentsData.length;

  const handleImageChange = async (event, labelId) => {
    const files = event.target.files;

    const imgInfos = await Promise.all(
      [...files].map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = async () => {
            const imgArr = reader.result.split(",");
            const imgInfo = {
              name: file.name,
              fileArray: imgArr[1],
              fileTypeId: 0,
            };
            resolve(imgInfo);
          };

          reader.readAsDataURL(file);
        });
      })
    );

    if (files.length) {
      setUploadedPhotos((prevPhotos) => ({
        ...prevPhotos,
        [labelId]: imgInfos,
      }));
    }
  };

  const hasUploadPhoto = (labelId) => !!uploadedPhotos[labelId]?.length;

  const handleViewPhotos = (labelId) => {
    const photos = uploadedPhotos[labelId];

    if (photos && photos.length > 0) {
      setOpenModal(true);
      setSelectedPhotos(photos);
    }
  };

  const handleNext = async () => {
    await dispatch(uploadFiles(uploadedPhotos));
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchDocumentTitles());
    };

    fetchData();
  }, [dispatch]);

  if (isLoading && !documentsData.length) {
    return (
      <Grid container justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container direction="column" spacing={1.5} mt={3}>
      <CustomModal open={openModal} title={t("view_photo")} onClose={() => setOpenModal(false)}>
        <ImageList cols={1}>
          {selectedPhotos.map((item) => (
            <ImageListItem key={item.name}>
              <img src={generateBase64ImageUrl(item.fileArray)} alt={item.name} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </CustomModal>

      {documentsData.map((item) => (
        <Grid item key={item.typeId}>
          <Box display="flex" justifyContent="space-between" alignItems="end" flexDirection="column">
            <Button
              fullWidth
              component="label"
              color={uploadedPhotos[item.typeId] ? "success" : "primary"}
              variant="contained"
              tabIndex={-1}
            >
              {item.description}
              <VisuallyHiddenInput onChange={(event) => handleImageChange(event, item.typeId)} multiple type="file" />
            </Button>
            {hasUploadPhoto(item.typeId) && (
              <Button
                sx={{ textTransform: "none" }}
                variant="text"
                color="primary"
                onClick={() => handleViewPhotos(item.typeId)}
              >
                {t("view_photo")}
              </Button>
            )}
          </Box>
        </Grid>
      ))}
      <Box display="flex" justifyContent="space-between" alignItems="end" flexDirection="column">
        <LoadingButton
          loading={isLoading}
          onClick={handleNext}
          color="inherit"
          sx={{ mt: 3, textTransform: "none" }}
          loadingPosition="center"
          variant="contained"
          disabled={shouldDisableBtn}
        >
          {t("next")}
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default memo(DocumentsStep);
