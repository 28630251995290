import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const secondsToMinSec = (seconds) => {
  const formattedTime = dayjs.duration(seconds, "seconds").format("mm:ss");
  return formattedTime;
};

export const secondsToMin = (seconds) => {
  const duration = dayjs.duration(seconds, "seconds");
  const durationInMinutes = duration.minutes();
  return durationInMinutes;
};

export const secondsToHoursMin = (seconds) => {
  if (seconds === 0) {
    return seconds;
  }
  const formattedTime = dayjs.duration(seconds, "seconds").format("hh:mm");
  return formattedTime;
};

export const sumCurrentTimeMinutes = (minutes) => {
  const currentTime = dayjs();
  const arrivalTime = currentTime.add(minutes, "minutes");
  return arrivalTime.format("HH:mm");
};
