import React from "react";
import { useTranslation } from "react-i18next";
import style from "./styles.module.scss";

const items = [
  {
    title: "block_three_item_1_title",
    description: "block_three_item_1_description",
    innerItems: [
      "block_three_item_1_innerItems_1",
      "block_three_item_1_innerItems_2",
      "block_three_item_1_innerItems_3",
      "block_three_item_1_innerItems_4",
    ],
  },
  {
    title: "block_three_item_2_title",
    description: "block_three_item_2_description",
  },
  {
    title: "block_three_item_3_title",
    description: "block_three_item_3_description",
  },
  {
    title: "block_three_item_4_title",
    description: "block_three_item_4_description",
  },
  {
    title: "block_three_item_5_title",
    description: "block_three_item_5_description",
  },
  {
    title: "block_three_item_6_title",
    description: "block_three_item_6_description",
  },
];

const BlockThree = () => {
  const { t } = useTranslation();
  return (
    <ul className={style.block_three_wrap}>
      {items.map((item, index) => (
        <li className={style.block_three_item} key={`${item.title}_${index}`}>
          <h4>{t(item.title)}</h4>
          <p className={style.block_three_item_desc}>{t(item.description)}</p>
          {item.innerItems && (
            <ul className={style.block_three_inner_item}>
              {item.innerItems.map((subItem, subItemIndex) => (
                <p key={`${subItem}_${subItemIndex}`}>
                  {subItemIndex + 1}. {t(subItem)}
                </p>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default BlockThree;
