import React, { useState } from "react";
import Cookies from "js-cookie";
import { t } from "i18next";

import styles from "./style.module.scss";
import ContentWrap from "src/shared/components/content-wrap";
import { allowGtagCookies } from "src/helper/allowGtagCookies";

const CookieBar = () => {
  const [showedCookieBar, setShowedCookieBar] = useState(
    Cookies.get("cookiebar") || false
  );

  if (showedCookieBar) {
    allowGtagCookies();
    return;
  }
  const setCookieBar = () => {
    Cookies.set("cookiebar", "CookieAllowed");
    allowGtagCookies();
    setShowedCookieBar(true);
  };
  return (
    <div className={styles.cookieBarWrap}>
      <ContentWrap>
        <div className={styles.cookieBar}>
          <p>{t("cookie_text")}</p>
          <button onClick={setCookieBar}>{t("allow_cookie")}</button>
        </div>
      </ContentWrap>
    </div>
  );
};

export default CookieBar;
