import React, { useEffect, useState } from "react";
import cls from "./peculiarities.module.scss";
import { useTranslation } from "react-i18next";
import PeculiaritiesFirstIcon from "../../../assets/peculiarities/PeculiaritiesFirstIcon.svg";
import PeculiaritiesSecondIcon from "../../../assets/peculiarities/PeculiaritiesSecondIcon.svg";
import PeculiaritiesThirdIcon from "../../../assets/peculiarities/PeculiaritiesThirdIcon.svg";
import PeculiaritiesFouthIcon from "../../../assets/peculiarities/PeculiaritiesFouthIcon.svg";
import PeculiaritiesFirst from "../../../assets/peculiarities/PeculiaritiesFirst.png";
import PeculiaritiesSecond from "../../../assets/peculiarities/PeculiaritiesSecond.png";
import PeculiaritiesSecondFull from "../../../assets/peculiarities/PeculiaritiesSecondFull.png";
import PeculiaritiesSecondOther from "../../../assets/peculiarities/PeculiaritiesSecondOther.png";
import PeculiaritiesSecondOtherFull from "../../../assets/peculiarities/PeculiaritiesSecondOtherFull.png";
import PeculiaritiesThird from "../../../assets/peculiarities/PeculiaritiesThird.png";
import PeculiaritiesFouth from "../../../assets/peculiarities/PeculiaritiesFouth.png";

const Peculiarities = () => {
  const { t } = useTranslation();
  // const [countryLS, setCountryLS] = useState(localStorage.getItem('country'));

  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     setCountryLS(localStorage.getItem('country'));
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);

  // const imageClassName = countryLS === 'ua' ? cls.image : cls.image + ' ' + cls.padding;

  return (
    <div className={cls.peculiarities_container}>
      <div className={cls.blocks_container}>
        <div className={cls.two_items}>
          <div className={cls.first_item}>
            <div className={cls.image}>
              <img src={PeculiaritiesFirst} alt="" />
            </div>
            <div className={cls.text_item}>
              <div className={cls.icon_block}>
                <img src={PeculiaritiesFirstIcon} alt="" />
              </div>
              <p>{t("main_text_8")}</p>
            </div>
          </div>
          <div className={cls.second_item}>
            {/* <div className={imageClassName}>
              {countryLS === 'ua' ? (
                <>
                  <img className={cls.unfull} src={PeculiaritiesSecond} alt='' />
                  <img className={cls.full} src={PeculiaritiesSecondFull} alt='' />
                </>
              ) : (
                <>
                  <img className={cls.unfull + ' ' + cls.padding} src={PeculiaritiesSecondOther} alt='' />
                  <img className={cls.full} src={PeculiaritiesSecondOtherFull} alt='' />
                </>
              )}
            </div> */}
            <div className={cls.image}>
              <img
                className={cls.unfull + " " + cls.padding}
                src={PeculiaritiesSecondOther}
                alt=""
              />
              <img
                className={cls.full}
                src={PeculiaritiesSecondOtherFull}
                alt=""
              />
            </div>
            <div className={cls.text_item}>
              <div className={cls.icon_block}>
                <img src={PeculiaritiesSecondIcon} alt="" />
              </div>
              <p>{t("main_text_9")}</p>
            </div>
          </div>
        </div>
        <div className={cls.other_two_items}>
          <div className={cls.first_item}>
            <div className={cls.image}>
              <img src={PeculiaritiesThird} alt="" />
            </div>
            <div className={cls.text_item}>
              <div className={cls.icon_block}>
                <img src={PeculiaritiesThirdIcon} alt="" />
              </div>
              <p>{t("main_text_10")}</p>
            </div>
          </div>
          <div className={cls.second_item}>
            <div className={cls.image}>
              <img src={PeculiaritiesFouth} alt="" />
            </div>
            <div className={cls.text_item}>
              <div className={cls.icon_block}>
                <img src={PeculiaritiesFouthIcon} alt="" />
              </div>
              <p>{t("main_text_11")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Peculiarities;
