import axios from "axios";

// default axiosInstance
export const axiosInstance = axios.create();

// axiosInstance with main api token
export const axiosInstanceWithMainApiToken = axios.create();

axiosInstanceWithMainApiToken.interceptors.request.use(function (config) {
  const mainApiAccessToken = localStorage.getItem("mainApiAccessToken");
  config.headers.Authorization = mainApiAccessToken ? `Bearer ${mainApiAccessToken}` : "";
  return config;
});
