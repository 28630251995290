import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./styles/index.scss";
import Loader from "./components/common-components/loader";
import Layout from "./components/common-components/layout";
import Hero from "./pages/hero";
import Passenger from "./pages/passenger";
import Driver from "./pages/driver";
import Tariffs from "./pages/tariffs";
import About from "./pages/about";
import CreateDriver from "./pages/create-driver";
import Footer from "./components/common-components/footer";
import { changeLanguage } from "i18next";
import { changeTheme } from "./helper/changeTheme";
import { LoaderContext } from "./context";
import Partner from "./pages/partner";
import { getCountry } from "./shared/api/language";
import PartnerLayout from "./components/header";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerTokens } from "./pages/partner/model/partnerSelectors";
import { partnerActions } from "./pages/partner/model/partnerSlice";
import Inspection from "./pages/inspection";
import Profile from "./pages/profile";
import DriverSalary from "./pages/partner-pages/driver-salary";

function App() {
  const [isLoading, setLoading] = useState(true);

  const partnerTokens = useSelector(getPartnerTokens);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const handleExit = () => {
    dispatch(partnerActions.logout());
    navigate("/partner");
  };

  const isPartnerPage = () => {
    const routes = [
      "/partner/create-driver",
      "/partner",
      "/partner/inspection",
      "/partner/profile",
      "/partner-pages",
    ];

    return routes.some((routeItem) => location.pathname.includes(routeItem));
  };

  const changePage = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    window.scrollTo(0, 0);
  };

  const value = {
    changePage,
  };

  useEffect(() => {
    const fetchData = () => {
      getCountry(changeLanguage, changeTheme, setLoading);
    };

    fetchData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <LoaderContext.Provider value={value}>
      {!isPartnerPage() ? (
        <Layout />
      ) : (
        <PartnerLayout partnerTokens={partnerTokens} handleExit={handleExit} />
      )}
      <Routes>
        <Route index element={<Hero />} />
        <Route path="/passenger" element={<Passenger />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/partner/create-driver" element={<CreateDriver />} />
        <Route path="/partner/profile" element={<Profile />} />
        <Route path="/partner/inspection" element={<Inspection />} />
        <Route path="/driver" element={<Driver />} />
        <Route path="/tariffs" element={<Tariffs />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/partner-pages/driver-salary/:driverId"
          element={<DriverSalary />}
        />
      </Routes>

      {!isPartnerPage() && <Footer />}
    </LoaderContext.Provider>
  );
}

export default App;
