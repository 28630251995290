import { memo, useCallback, useEffect, useState } from "react";
import { Box, Paper, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { loginPartner } from "src/pages/partner/model/partnerAsyncActions";
import { authSchema } from "./schemas";
import { t } from "i18next";

const initialValues = {
  email: "",
  password: "",
};

const paperStyle = {
  padding: 20,
  minWidth: "35%",
  margin: "20px auto",
};

const AuthPartner = (props) => {
  const { isLoading, errors } = props;

  const [validation, setValidation] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema: authSchema,
    validateOnChange: validation,
    validateOnBlur: validation,
    onSubmit: async (values) => {
      await dispatch(loginPartner(values));
    },
  });

  const handleFormSubmit = useCallback((event) => {
    setValidation(true);
    formik.handleSubmit(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errors?.general) {
      formik.setErrors({ general: errors.general });
      return;
    }

    if (errors) {
      Object.entries(errors).forEach(([field, error]) => {
        formik.setFieldError(field, error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <Paper elevation={7} style={paperStyle}>
      <Typography align="center" variant="h5">
        {t("sign_in")}
      </Typography>
      <form onSubmit={handleFormSubmit}>
        <Box mt={2}>
          <TextField
            value={formik.values.email}
            onChange={formik.handleChange}
            label={t("email")}
            name="email"
            fullWidth
            error={Boolean(formik.errors.email || formik.errors.general)}
            helperText={t(formik.errors.email)}
            required
          />
        </Box>
        <Box mt={2}>
          <TextField
            value={formik.values.password}
            onChange={formik.handleChange}
            label={t("password")}
            name="password"
            type="password"
            error={Boolean(formik.errors.password || formik.errors.general)}
            helperText={t(formik.errors.password || formik.errors.general)}
            fullWidth
            required
          />
        </Box>
        <Box mt={2}>
          <LoadingButton
            size="large"
            type="submit"
            loading={isLoading}
            loadingPosition="center"
            variant="contained"
            fullWidth
          >
            {t("sign_in")}
          </LoadingButton>
        </Box>
      </form>
    </Paper>
  );
};

export default memo(AuthPartner);
