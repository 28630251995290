import React from 'react';
import cls from "./features.module.scss"
import { useTranslation } from 'react-i18next';
import FeaturesFirst from '../../../assets/features/FeaturesFirst.svg';
import FeaturesSecond from '../../../assets/features/FeaturesSecond.svg';
import FeaturesThird from '../../../assets/features/FeaturesThird.svg';

const Features = () => {
  const { t } = useTranslation()
  return (
    <div className={cls.features_container}>
      <p className={cls.main_text_4}>{t('main_text_4')}</p>
      <div className={cls.features_block}>
        <div className={cls.item_block}>
          <div className={cls.icon_block}>
            <img src={FeaturesFirst} alt='' />
          </div>
          <div className={cls.text_block}>
            <p className={cls.main_text}>{t('comforted')}</p>
            <p className={cls.second_text}>{t('main_text_5')}</p>
          </div>
        </div>
        <div className={cls.item_block}>
          <div className={cls.icon_block}>
            <img src={FeaturesSecond} alt='' />
          </div>
          <div className={cls.text_block}>
            <p className={cls.main_text}>{t('fasted')}</p>
            <p className={cls.second_text}>{t('main_text_6')}</p>
          </div>
        </div>
        <div className={cls.item_block}>
          <div className={cls.icon_block}>
            <img src={FeaturesThird} alt='' />
          </div>
          <div className={cls.text_block}>
            <p className={cls.main_text}>{t('economed')}</p>
            <p className={cls.second_text + ' ' + cls.last_item}>{t('main_text_7')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;