import React, { useEffect, useState } from 'react';
import cls from "./tariffs.module.scss"
import Table from '../../components/tariffs-components/table';
import TableEUR from '../../components/tariffs-components/tableEUR';

const Tariffs = () => {

  // const [countryLS, setCountryLS] = useState(localStorage.getItem('country'));

  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     setCountryLS(localStorage.getItem('country'));
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);

  return (
    <>
      <div className={cls.tariffs_container}>
        {/* {countryLS === 'ua' ? (
          <Table />
        ) : (
          <TableEUR />
        )} */}
        <Table />
      </div>
    </>
  );
}

export default Tariffs;
