import { t } from "i18next";
import React from "react";

import styles from "./styles.module.scss";
import { defaultNumInUndefined, salaryAmountCategories } from "../../constants";
import { convertCoinsToMoney } from "../../helpers/convertCoinsToMoney";

const SalaryDetails = ({ currentChartItem, currency, currentPayment, salaryData }) => {
  const { amount } = currentChartItem;
  const { earnAmountByCategories, totalAmountInCoins } = salaryData;

  const detailsItems = [
    {
      title: `${t("earn")}`,
      value: `${convertCoinsToMoney({ coins: amount || 0 })}`,
      subItems: () => {
        switch (currentPayment.value) {
          case salaryAmountCategories.cash:
            return [
              {
                title: `${t("cash")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.cash.total })}`,
              },
            ];
          case salaryAmountCategories.inApp:
            return [
              {
                title: `${t("in_app")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.inApp.total })}`,
              },
              {
                title: `${t("compensation")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.compensations.total })}`,
              },
              {
                title: `${t("cancellation_fee")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.declineFee.total })}`,
              },
              {
                title: `${t("tips")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.tips.total })}`,
              },
            ];
          default:
            return [
              {
                title: `${t("in_app")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.inApp.total })}`,
              },
              {
                title: `${t("cash")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.cash.total })}`,
              },
              {
                title: `${t("compensation")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.compensations.total })}`,
              },
              {
                title: `${t("cancellation_fee")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.declineFee.total })}`,
              },
              {
                title: `${t("tips")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.tips.total })}`,
              },
            ];
        }
      },
    },
    {
      title: `${t("expenses")}`,
      value: `${convertCoinsToMoney({ coins: currentChartItem.fee || defaultNumInUndefined })}`,
      subItems: () => {
        switch (currentPayment.value) {
          default:
            return [
              {
                title: `${t("commission")}`,
                value: `${convertCoinsToMoney({ coins: currentChartItem.fee || defaultNumInUndefined })}`,
              },
            ];
        }
      },
    },
    {
      title: `${t("net_income")}`,
      value: `${convertCoinsToMoney({ coins: Number(totalAmountInCoins) - Number(currentChartItem.fee || defaultNumInUndefined) })}`,
    },
  ];

  return (
    <div className={styles.detailsItemsWrap}>
      {detailsItems.map((item, index) => (
        <li className={styles.detailsItem} key={`${item.title}_${index}`}>
          <div className={styles.detailItemTitle}>
            <p>{item.title}</p>
            <p>
              {item.value} {currency}
            </p>
          </div>
          <div className={styles.subItemsWrap}>
            {typeof item.subItems === "function" &&
              item.subItems().map((subItem, index) => (
                <div className={styles.subItem} key={`${subItem.title}_${index}`}>
                  <p>{subItem.title}</p>
                  <p>
                    {subItem.value} {currency}
                  </p>
                </div>
              ))}
          </div>
        </li>
      ))}
    </div>
  );
};

export default SalaryDetails;
