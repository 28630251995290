import dayjs from "dayjs";
import React, { useCallback } from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell } from "recharts";
import styles from "./styles.module.scss";
import { t } from "i18next";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CharGraphic = ({ items, currentChartItem, currency, setCurrentChartItem }) => {
  const handleClick = useCallback(
    (entryData, index) => {
      setCurrentChartItem({ ...entryData.payload, activeIndex: index });
    },
    [setCurrentChartItem],
  );

  const underCharItems = currentChartItem
    ? [
        {
          title: capitalizeFirstLetter(dayjs(currentChartItem.date).format("dd, D MMMM")),
        },
        {
          title: `${currentChartItem.amount}${currency}`,
        },
        {
          title: `- ${currentChartItem.fee}${currency}`,
        },
      ]
    : null;

  return (
    <div>
      <div style={{ height: "160px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={items}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="dateForChart" />
            <YAxis dataKey="amount" orientation="right" />
            <Bar dataKey="amount" onClick={handleClick}>
              {items.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={index === currentChartItem?.activeIndex ? "#1db960" : "rgba(142, 220, 176, 1"}
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      {underCharItems && (
        <div className={styles.underCharItemsWrap}>
          {underCharItems.map((item, index) => (
            <li key={`${item.title}_${index}`}>{item.title}</li>
          ))}
        </div>
      )}
    </div>
  );
};

export default CharGraphic;
