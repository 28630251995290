import React from "react";
import Cookies from "js-cookie";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import styles from "./styles.module.scss";

const CountryPhoneSelect = (props) => {
  const { formik, onChange, placeholder, label, className, value, name, error, enableSearch, ...rest } = props;

  const countryCode = Cookies.get("country")?.toLowerCase() || "sk";

  const handleChange = (value) => {
    if (onChange) {
      return onChange();
    }

    if (formik) {
      const { touched, setFieldTouched, setFieldValue } = formik;

      if (!touched[name]) {
        setFieldTouched(name);
      }

      setFieldValue(name, value);
    }
  };

  return (
    <PhoneInput
      onChange={handleChange}
      enableSearch={enableSearch}
      error={true}
      isValid={!error}
      className={className}
      containerClass={styles.CountryPhoneSelect}
      value={value}
      name={name}
      placeholder={placeholder}
      specialLabel={label}
      country={countryCode}
      {...rest}
    />
  );
};

export default CountryPhoneSelect;
