import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as AvatarIcon } from "../../assets/AvatarIcon.svg";
import { ReactComponent as ArrowBackIcon } from "../../assets/ArrowBackIcon.svg";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { handleApprove, handleReject } from "./helpers";
import { chgInspectionStatus } from "../partner/model/partnerAsyncActions";
import { getInspectionInfo } from "../partner/model/partnerSelectors";
import { useState } from "react";
import "./styles.scss"

const Inspection = () => {
    const data = useSelector(getInspectionInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [status, setStatus] = useState(data.data.status);

    let statusText;
    switch (status) {
        case 0:
            statusText = t('new');
            break;
        case 1:
            statusText = t('approved');
            break;
        case 2:
            statusText = t('rejected');
            break;
        default:
            statusText = '';
    }

    const addLeadingZero = (value) => {
        const stringValue = value.toString();
        return stringValue.length === 1 ? `0${stringValue}` : stringValue;
    };

    const handleGoBack = () => {
        navigate(-1);
    }

    const formatDateTime = (dateTimeString) => {
        if (dateTimeString) {
            const options = {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
            };

            const formattedDate = new Date(dateTimeString).toLocaleString('en-US', options);

            const [datePart] = formattedDate.split(', ');
            const [month, day, year] = datePart.split('/');

            const formattedDay = addLeadingZero(day);
            const formattedMonth = addLeadingZero(month);

            return `${formattedDay}.${formattedMonth}.${year}`;
        } else {
            return '';
        }
    };

    const formattedCreatedAt = formatDateTime(data.data.createdAt);

    const inputFields = [
        {
            items: [
                { label: t('car_mark'), value: data.data.brand },
                { label: t('car_model'), value: data.data.model },
                { label: t('car_class'), value: data.data.class, withSelectCountry: true }
            ]
        },
        {
            items: [
                { label: t('application_date'), value: formattedCreatedAt },
                { label: t('country'), value: data.data.country },
                { label: t('status'), value: statusText }
            ]
        }
    ];

    return (
        <div className='inspection_details_page'>
            <div className='inspections_main_block'>
                <div className='main_driver_info'>
                    <div className="go_back_btn" onClick={handleGoBack}>
                        <ArrowBackIcon />
                        <p className='go_back_text'>{t('go_back')}</p>
                    </div>
                    <div className='driver_details_info'>
                        <AvatarIcon />
                        <div className='main_info'>
                            <p className='main_text'>{t('driver')}</p>
                            <p className='secondary_text'>{data.data.driverName}</p>
                        </div>
                    </div>
                </div>
                <div className='inspections_info_block'>
                    <div className='inspections_info_grounds'>
                        {inputFields.map((group, index) => (
                            <div className='inspections_info_ground' key={index}>
                                {group.items.map((item, idx) => (
                                    <div className='inspection_input disabled_inputs' key={idx}>
                                        <p><span>*</span> {item.label}</p>
                                        <div className={item.withSelectCountry ? 'select-country' : ''}>
                                            <input value={item.value} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <p className='inspections_data_title block_with_padding_top'>{t('technical_inspection')} <span className='data_title_span'>{formattedCreatedAt}</span></p>
                <div className='inspection_car_block'>
                    {data.data.files.map(file => (
                        <div className='inspection_car_single_item' key={file.id}>
                            <img src={file.file} alt='' />
                        </div>
                    ))}
                </div>
                <div className='info-buttons'>
                    <Button color="error" variant="contained" onClick={() => handleReject(data.data.id, setStatus, chgInspectionStatus, dispatch)}>
                        {t('reject')}
                    </Button>
                    {(status === 0 || status === 2) &&
                        <>
                            <div className='border-buttons' />
                            <Button color="success" variant="contained" onClick={() => handleApprove(data.data.id, setStatus, chgInspectionStatus, dispatch)}>
                                {t('approve')}
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Inspection;
