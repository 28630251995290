import React from 'react';
import cls from "./hang.module.scss"
import { useTranslation } from 'react-i18next';
import HangFirst from '../../../assets/hang/HangFirst.svg';
import HangSecond from '../../../assets/hang/HangSecond.svg';
import HangThird from '../../../assets/hang/HangThird.svg';

const Hang = () => {
  const { t } = useTranslation()
  return (
    <div className={cls.hang_container}>
      <p className={cls.main_text_4}>{t('main_text_4')}</p>
      <div className={cls.hang_block}>
        <div className={cls.item_block}>
          <div className={cls.icon_block}>
            <img src={HangFirst} alt='' />
          </div>
          <div className={cls.text_block}>
            <p className={cls.main_text}>{t('main_text_30')}</p>
            <p className={cls.second_text}>{t('main_text_31')}</p>
          </div>
        </div>
        <div className={cls.item_block}>
          <div className={cls.icon_block}>
            <img src={HangSecond} alt='' />
          </div>
          <div className={cls.text_block}>
            <p className={cls.main_text}>{t('main_text_32')}</p>
            <p className={cls.second_text}>{t('main_text_33')}</p>
          </div>
        </div>
        <div className={cls.item_block}>
          <div className={cls.icon_block}>
            <img src={HangThird} alt='' />
          </div>
          <div className={cls.text_block}>
            <p className={cls.main_text}>{t('main_text_34')}</p>
            <p className={cls.second_text}>{t('main_text_35')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hang;