import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import dayjs from "dayjs";

import { ReactComponent as MoneyCash } from "src/assets/MoneyCash.svg";
import { ReactComponent as MoneyCard } from "src/assets/MoneyCard.svg";

import SalaryModuleDate from "./components/SalaryModuleDate";
import SalaryModulePayments from "./components/SalaryModulePayments";
import Statistic from "./components/Statistic";
import { differentDays } from "./helpers/differentDays";

import styles from "./styles.module.scss";
import useChartsItems from "./hooks/useChartsItems";
import CharGraphic from "./components/CharGraphic";
import SalaryDetails from "./components/SalaryDetails";
import { dateToCorrectFormat } from "./helpers/dayToCorrectFormat";
import ShowDetailedWrap from "./components/ShowDetailedWrap";
import OpenByHeight from "src/shared/components/open-by-height";

const SalaryModule = (props) => {
  const { currency, salaryData, setDateFrom, setDateTo, dateFrom, dateTo } =
    props;
  const { currencyValue, currencyName } = currency;

  const dateItems = [
    { name: t("today-day"), value: 0 },
    {
      name: t("days_with_num", { day: 7 }),
      value: 7,
    },
    {
      name: t("days_with_num", { day: 30 }),
      value: 30,
    },
  ];

  const paymentsItems = [
    { name: t("all_income_money"), img: "", value: "totalAmountInCoins" },
    { name: t("cash"), img: <MoneyCash />, value: "cash" },
    { name: t("card"), img: <MoneyCard />, value: "inApp" },
  ];
  const [currentPayment, setCurrentPayment] = useState(paymentsItems[0]);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [currentChartItem, setCurrentChartItem] = useState(null);

  const currentSalaryItems = useChartsItems({ salaryData, currentPayment });
  const currentSalaryItemsAdapter = useMemo(() => {
    const updatedSalary = currentSalaryItems.map((item) => ({
      ...item,
      date: dateToCorrectFormat(item.date),
      dateForChart: dayjs(item.date).format("DD.MM"),
      amount: item.amount,
    }));

    setCurrentChartItem((prevItem) => {
      const currentItemByDate = updatedSalary.find(
        (item) => item.date === prevItem?.date
      );
      const currentItemByDateIndex = updatedSalary.indexOf(currentItemByDate);
      return currentItemByDate
        ? { ...currentItemByDate, activeIndex: currentItemByDateIndex }
        : { ...updatedSalary[0], activeIndex: 0 }; // Return currentItem if found, otherwise return prevItem
    });

    return updatedSalary;
  }, [currentSalaryItems]);

  // update date
  const setDate = (date) => {
    const updatedDate = dayjs();
    const differentDate = differentDays({
      date: updatedDate,
      numberOfDays: date.value,
    });
    setDateFrom(dateToCorrectFormat(differentDate));
    setDateTo(dateToCorrectFormat(updatedDate));
    setCurrentDate(updatedDate);
  };

  // only props for date picker
  const datePickerProps = {
    dateRange: [dateFrom, dateTo],
    setDateRange: (dateRange) => {
      setDateFrom(dateRange[0]);
      setDateTo(dateRange[1]);
    },
  };

  // title date for date range
  const paymentsTitleDate = (() => {
    const formattedDateFrom = dayjs(dateFrom).format("DD.MM.YY");
    const formattedDateTo = dayjs(dateTo).format("DD.MM.YY");
    switch (true) {
      case formattedDateFrom === formattedDateTo:
        return `${formattedDateTo}`;
      default:
        return `${formattedDateFrom} - ${formattedDateTo}`;
    }
  })();

  const statisticProps = {
    statistics: salaryData.statistics,
    earnAmountByCategories: salaryData.earnAmountByCategories,
    paymentsTitleDate,
    totalAmountInCoins: salaryData.totalAmountInCoins,
    currency: currencyValue,
    currencyName,
    currentPayment,
  };

  return (
    <div className={styles.salaryModuleWrap}>
      <SalaryModuleDate
        currentSelectDate={{ dateFrom, dateTo }}
        dateItems={dateItems}
        datePickerProps={datePickerProps}
        setCurrentSelectDate={setDate}
        currentDate={currentDate}
      />
      <SalaryModulePayments
        currentPayment={currentPayment}
        paymentsItems={paymentsItems}
        setCurrentPayment={setCurrentPayment}
      />
      <Statistic {...statisticProps} />

      <OpenByHeight
        isOpen={
          currentSalaryItemsAdapter && currentSalaryItemsAdapter.length > 1
        }
      >
        <CharGraphic
          items={currentSalaryItemsAdapter}
          currentChartItem={currentChartItem}
          currency={currencyValue}
          setCurrentChartItem={setCurrentChartItem}
        />
      </OpenByHeight>

      <ShowDetailedWrap>
        <SalaryDetails
          currentChartItem={currentChartItem}
          currency={currencyValue}
          currentPayment={currentPayment}
          salaryData={salaryData}
        />
      </ShowDetailedWrap>
    </div>
  );
};

export default SalaryModule;
