import { configureStore } from "@reduxjs/toolkit";
import { partnerReducer } from "src/pages/partner/model/partnerSlice";
import { driverReducer } from "src/pages/create-driver/model/driverSlice";
import { driverSalaryQuery } from "src/pages/partner-pages/driver-salary/store/queries/salary-query";

export function createReduxStore() {
  const rootReducer = {
    partner: partnerReducer,
    driver: driverReducer,

    // rtk query reducers
    [driverSalaryQuery.reducerPath]: driverSalaryQuery.reducer,
  };

  return configureStore({
    reducer: rootReducer,

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(driverSalaryQuery.middleware),
  });
}
