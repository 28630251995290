import React from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ReactComponent as ArrowBackIcon } from "src/assets/ArrowBackIcon.svg";

const ContentWrap = ({ children, className, withBackButton = false }) => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.contentWrap} ${className ? className : ""}`}>
      {withBackButton && (
        <div className={styles.backButton} onClick={() => navigate(-1)}>
          <ArrowBackIcon />
          <p>{t("go_back")}</p>
        </div>
      )}
      {children}
    </div>
  );
};

export default ContentWrap;
