import React from "react";
import { ReactComponent as AppStoreLabel } from "src/assets/AppStoreLabel.svg";
import { ReactComponent as PlayMarketLabel } from "src/assets/PlayMarketLabel.svg";
import style from "./styles.module.scss";

const YellowBlock = ({ textArray }) => {
  return (
    <div className={style.yellow_block_wrap}>
      <ul>
        {textArray.map((item, index) => (
          <p
            key={`${item.text}_w${index}`}
            className={`${item?.bold ? style.yellow_block_text_bold : ""} `}
          >
            {item.text}
          </p>
        ))}
      </ul>

      <div className={style.buttons_wrap}>
        <a href="https://apps.apple.com/ua/app/carma-taxi/id6465251519?l=ru">
          <AppStoreLabel className={style.unfull_link} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.carmataxi.client">
          <PlayMarketLabel className={style.unfull_link} />
        </a>
      </div>
    </div>
  );
};

export default YellowBlock;
