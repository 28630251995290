import { sendFile } from "src/shared/api/sendFile";

export const uploadFile = async (file) => {
  const fileInfo = {
    name: file.name,
    fileArray: file.fileArray,
    fileTypeId: file.fileTypeId,
  };

  // Отправляем файл на сервер и получаем результат
  return await sendFile(fileInfo);
};
