import React, { useEffect, useState } from 'react';
import cls from "./partnership.module.scss"
import { Link } from '../../../helper/Link';
import { useTranslation } from 'react-i18next';
import CheckIcon from "../../../assets/CheckIcon.svg"
import PointIcon from "../../../assets/PointIcon.svg"

const Partnership = () => {
  const { t } = useTranslation()
  // const [countryLS, setCountryLS] = useState(localStorage.getItem('country'));

  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     setCountryLS(localStorage.getItem('country'));
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);

  return (
    <div className={cls.partnership_container}>
      <div className={cls.partnership_block}>
        <div className={cls.item_block}>
          <p className={cls.main_text}>{t('main_text_12')}</p>
          <Link to="/driver" className={cls.main_btn}>
            {t('partner_btn')}
          </Link>
        </div>
        <div className={cls.text_block}>
          <div className={cls.block}>
            <div className={cls.icon}>
              <div className={cls.image}>
                {/* {countryLS === 'ua' ? (
                  <img src={CheckIcon} alt='' />
                ) : (
                  <img src={PointIcon} alt='' />
                )} */}
                <img src={PointIcon} alt='' />
              </div>
            </div>
            <div className={cls.text}>
              <p className={cls.first_text}>{t('own_schedule')}</p>
              <p className={cls.second_text}>{t('main_text_13')}</p>
            </div>
          </div>
          <div className={cls.block}>
            <div className={cls.icon}>
                {/* {countryLS === 'ua' ? (
                  <img src={CheckIcon} alt='' />
                ) : (
                  <img src={PointIcon} alt='' />
                )} */}
                <img src={PointIcon} alt='' />
            </div>
            <div className={cls.text}>
              <p className={cls.first_text}>{t('confort_app')}</p>
              <p className={cls.second_text}>{t('main_text_14')}</p>
            </div>
          </div>
          <div className={cls.block}>
            <div className={cls.icon}>
                {/* {countryLS === 'ua' ? (
                  <img src={CheckIcon} alt='' />
                ) : (
                  <img src={PointIcon} alt='' />
                )} */}
                <img src={PointIcon} alt='' />
            </div>
            <div className={cls.text}>
              <p className={cls.first_text}>{t('weekly_income')}</p>
              <p className={cls.second_text}>{t('main_text_15')}</p>
            </div>
          </div>
          <div className={cls.block}>
            <div className={cls.icon}>
                {/* {countryLS === 'ua' ? (
                  <img src={CheckIcon} alt='' />
                ) : (
                  <img src={PointIcon} alt='' />
                )} */}
                <img src={PointIcon} alt='' />
            </div>
            <div className={cls.text}>
              <p className={cls.first_text}>{t('work_on_our_car')}</p>
              <p className={cls.second_text}>{t('main_text_16')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partnership;