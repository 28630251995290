import React from "react";
import styles from "./styles.module.scss";

const OpenByHeight = ({ isOpen, children }) => {
  return (
    <div
      className={`${styles.detailedContentWrap} ${isOpen ? styles.detailedContentWrapActive : ""}`}
    >
      <div className={styles.detailedContent}>{children}</div>
    </div>
  );
};

export default OpenByHeight;
