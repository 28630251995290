import React from 'react';
import cls from "./bracket.module.scss"
import { useTranslation } from 'react-i18next';
import PassangerPhoto from "../../../assets/PassangerPhoto.png"
import PassangerPhotoFull from "../../../assets/PassangerPhotoFull.png"

const Bracket = () => {
  const { t } = useTranslation()
  return (
    <div className={cls.bracket_container}>
      <div className={cls.text_container}>
        <p className={cls.main_text}>{t('main_text_17')}</p>
        <p className={cls.second_text}>{t('main_text_18')}</p>
      </div>
      <div className={cls.main_block}>
        <img className={cls.unfull} src={PassangerPhoto} alt='' />
        <img className={cls.full} src={PassangerPhotoFull} alt='' />
      </div>
    </div>
  );
}

export default Bracket;