import { memo, useCallback } from "react";
import { Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { DriverRegistrationStep } from "src/shared/constants/driver";
import CompleteStep from "./registration-steps/complete-step";
import DocumentsStep from "./registration-steps/documents-step";
import ProfileStep from "./registration-steps/profile-step";
import { t } from "i18next";

const steps = ["Profile", "Documents", "Complete"];
const paperStyle = {
  padding: 20,
  minWidth: "50%",
  margin: "20px auto",
};

const DriverRegistrationForm = (props) => {
  const { activeStep, isLoading, driverData, documentsData, driverActions, errors } = props;

  const handleSteps = useCallback(
    (step) => {
      const defaultProps = {
        activeStep,
        isLoading,
      };

      switch (step) {
        case DriverRegistrationStep.Profile:
          return <ProfileStep {...defaultProps} driverActions={driverActions} errors={errors} />;
        case DriverRegistrationStep.Documents:
          return <DocumentsStep driverData={driverData} documentsData={documentsData} {...defaultProps} />;
        case DriverRegistrationStep.Complete:
          return <CompleteStep driverActions={driverActions} documentsData={documentsData} {...defaultProps} />;
        default:
          return <ProfileStep {...defaultProps} />;
      }
    },
    [activeStep, isLoading, errors, driverData, documentsData, driverActions]
  );

  return (
    <Paper elevation={7} style={paperStyle}>
      <Typography variant="h4" mb={5} align="center">
        {t("driver_registration")}
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {handleSteps(activeStep)}
    </Paper>
  );
};

export default memo(DriverRegistrationForm);
