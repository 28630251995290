import React, { useState } from "react";
import styles from "./styles.module.scss";
import DatePickerComponent from "../DatePickerComponent";
import { ReactComponent as Calendar } from "src/assets/Calendar.svg";
import { differentDays } from "../../helpers/differentDays";
import { dateToCorrectFormat } from "../../helpers/dayToCorrectFormat";

const SalaryModuleDate = ({
  currentDate,
  dateItems,
  currentSelectDate,
  setCurrentSelectDate,
  datePickerProps,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const { dateFrom, dateTo } = currentSelectDate;

  const isActiveDate = (value) => {
    const isActive =
      dateToCorrectFormat(dateFrom) ===
      dateToCorrectFormat(differentDays({ date: currentDate, numberOfDays: value }));
    return isActive;
  };

  const isCalendarActive = () => {
    const noOneFIlter = dateItems.every((item) => !isActiveDate(item.value));
    return noOneFIlter;
  };

  return (
    <ul className={styles.salaryModuleDateWrap}>
      <div className={styles.salaryModuleDateItems}>
        {dateItems.map((item, index) => (
          <li
            onClick={() => setCurrentSelectDate(item)}
            className={`${isActiveDate(item.value) ? styles.activeDate : ""}`}
            key={`${item.name}_${index}`}
          >
            {item.name}
          </li>
        ))}
      </div>
      <Calendar
        className={`${styles.calendarIcon} ${isCalendarActive() ? styles.activeDate : ""}`}
        onClick={() => setShowCalendar(!showCalendar)}
      />
      {showCalendar && (
        <div className={styles.salaryDatePickerWrap}>
          <DatePickerComponent className={styles.datePickerStyles} {...datePickerProps} />
        </div>
      )}
    </ul>
  );
};

export default SalaryModuleDate;
