import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const CustomModal = ({ open, title, onClose, children }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle align="center">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
