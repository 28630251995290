import { createSlice } from "@reduxjs/toolkit";
import { createDriver, fetchDocumentTitles, uploadFiles } from "./driverAsyncActions";
import { DriverRegistrationStep } from "src/shared/constants/driver";

const initialState = {
  activeStep: DriverRegistrationStep.Profile,
  errors: null,
  isLoading: false,
  driverData: {},
  documentsData: [],
};

const handlePending = (state) => {
  state.isLoading = true;
  state.errors = null;
};

const handleFulfilled = (state) => {
  state.isLoading = false;
  state.errors = null;
};

const handleRejected = (state, action) => {
  state.errors = action.payload;
  state.isLoading = false;
};

const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    setNextStep: (state, action) => {
      state.activeStep = action.payload;
    },
    clearError: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create driver
      .addCase(createDriver.pending, handlePending)
      .addCase(createDriver.fulfilled, (state, action) => {
        state.driverData = action.payload;
        state.errors = {};
      })
      .addCase(createDriver.rejected, handleRejected)

      // Get title for documents
      .addCase(fetchDocumentTitles.pending, handlePending)
      .addCase(fetchDocumentTitles.fulfilled, (state, action) => {
        state.documentsData = action.payload.documents;
        handleFulfilled(state, action);
      })
      .addCase(fetchDocumentTitles.rejected, handleRejected)

      // Send photo documents
      .addCase(uploadFiles.pending, handlePending)
      .addCase(uploadFiles.fulfilled, handleFulfilled)
      .addCase(uploadFiles.rejected, handleRejected);
  },
});

export const { actions: driverActions } = driverSlice;
export const { reducer: driverReducer } = driverSlice;
