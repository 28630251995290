import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import FeaturesFirst from "src/assets/features/FeaturesFirst.svg";
import FeaturesSecond from "src/assets/features/FeaturesSecond.svg";
import FeaturesThird from "src/assets/features/FeaturesThird.svg";

const items = [
  {
    title: "second_block_item_1",
    description: "second_block_desc_1",
    image: FeaturesFirst,
  },
  {
    title: "second_block_item_2",
    description: "second_block_desc_2",
    image: FeaturesSecond,
  },
  {
    title: "second_block_item_3",
    description: "second_block_desc_3",
    image: FeaturesThird,
  },
  {
    title: "second_block_item_4",
    description: "second_block_desc_4",
    image: FeaturesFirst,
  },
  {
    title: "second_block_item_5",
    description: "second_block_desc_5",
    image: FeaturesSecond,
  },
];

const BlockTwo = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.block_two_wrap}>
      <h2>{t("main_text_45")}</h2>
      <p className={styles.block_two_second_text}>{t("main_text_46")}</p>
      <ul className={styles.block_two_items}>
        {items.map((item, index) => (
          <li key={`${item.title}_${index}`}>
            <div className={styles.block_two_image_wrap}>
              <img src={item.image} alt={item.title} />
            </div>
            <div className={styles.block_two_inner_content}>
              <p className={styles.block_two_inner_content_title}>{t(item.title)}</p>
              <p className={styles.block_two_inner_content_description}>{t(item.description)}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlockTwo;
