import React, { memo, useEffect, useState } from "react";
import SalaryModule from "src/features/salary-module";
import { dateToCorrectFormat } from "src/features/salary-module/helpers/dayToCorrectFormat";
import dayjs from "dayjs";
import ContentWrap from "src/shared/components/content-wrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDriverInfo,
  fetchPartnerDriversList,
} from "src/pages/partner/model/partnerAsyncActions";
import {
  getDriverInfo,
  getPartnerDriversList,
} from "src/pages/partner/model/partnerSelectors";
import CircularLoader from "src/shared/components/loaders/circle-loader";
import { currency } from "src/shared/constants/currency";
import { useGetDriverSalaryDataQuery } from "./store/queries/salary-query";

const DriverSalary = () => {
  const { driverId } = useParams();
  const dispatch = useDispatch();
  const driverInfo = useSelector(getDriverInfo);

  const [salaryDateFrom, setSalaryDateFrom] = useState(
    dateToCorrectFormat(dayjs())
  );
  const [salaryDateTo, setSalaryDateTo] = useState(
    dateToCorrectFormat(dayjs())
  );

  useEffect(() => {
    const fetchDriver = async () => {
      await dispatch(fetchDriverInfo(driverId));
    };

    fetchDriver();
  }, [dispatch, driverId]);

  const { data, error, isLoading } = useGetDriverSalaryDataQuery({
    driverId,
    from: salaryDateFrom,
    to: salaryDateTo,
  });

  const isLoaded = driverInfo && !isLoading && data;

  return (
    <ContentWrap withBackButton>
      {!isLoaded ? (
        <CircularLoader />
      ) : (
        <SalaryModule
          salaryData={data}
          dateFrom={salaryDateFrom}
          dateTo={salaryDateTo}
          setDateFrom={(date) => setSalaryDateFrom(date)}
          setDateTo={(date) => setSalaryDateTo(date)}
          currency={{
            currencyValue: currency[driverInfo.currency],
            currencyName: driverInfo.currency,
          }}
        />
      )}
    </ContentWrap>
  );
};

export default memo(DriverSalary);
