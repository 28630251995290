import React from 'react';
import cls from "./header.module.scss"
import { useTranslation } from 'react-i18next';
import HeaderFirst from "../../../assets/header/HeaderFirst.png"
import HeaderSecond from "../../../assets/header/HeaderSecond.png"
import HeaderThird from "../../../assets/header/HeaderThird.png"

const Header = () => {
  const { t } = useTranslation()
  return (
    <div className={cls.header_container}>
      <div className={cls.main_photo}>
        <img className={cls.first_photo} src={HeaderFirst} alt='' />
        <img className={cls.second_photo} src={HeaderSecond} alt='' />
        <img className={cls.third_photo} src={HeaderThird} alt='' />
        <p className={cls.main_text} style={{ whiteSpace: 'pre-line' }}>{t('main_text_25')}</p>
      </div>
    </div>
  );
}

export default Header;