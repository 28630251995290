import React from 'react';
import cls from "./description.module.scss"
import { useTranslation } from 'react-i18next';
import AdvantagesFirst from "../../../assets/advantages/AdvantagesFirst.svg"

const Description = () => {
  const { t } = useTranslation()
  return (
    <div className={cls.description_container}>
      <div className={cls.main_block}>
        <div className={cls.icon_main}>
          <div className={cls.description_item}>
            <img src={AdvantagesFirst} alt='' />
          </div>
          <div className={cls.links}>
            <p className={cls.main_text_2}>{t('main_text_28')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;