import React from "react";
import cls from "./hero.module.scss";
import Features from "../../components/hero-components/features";
import Peculiarities from "../../components/hero-components/peculiarities";
import Partnership from "../../components/hero-components/partnership";
import MainTitle from "src/shared/components/main-title";
import Cookies from "js-cookie";
import { allCounties } from "src/shared/constants/country";
import { useTranslation } from "react-i18next";
import YellowBlock from "src/shared/components/yellow-block";
import BlockTwo from "src/components/hero-components/block-two";
import BlockThree from "src/components/hero-components/block-three";
import SkView from "src/components/hero-components/starter/views/sk-view";
import UaView from "src/components/hero-components/starter/views/ua-view";
import ContentWrap from "src/shared/components/content-wrap";

const Hero = () => {
  const country = Cookies.get("country");
  const { t } = useTranslation();

  const getViewByCountry = () => {
    switch (country) {
      case allCounties.UA:
        return (
          <>
            <UaView />
            <Features />
            <MainTitle text={t("porpouse")} />
          </>
        );
      default:
        return (
          <>
            <SkView />
            <MainTitle text={t("main_text_42")} />
            <YellowBlock
              textArray={[
                { text: t("main_text_43") },
                { text: t("main_text_44"), bold: true },
              ]}
            />
            <BlockTwo />
            <YellowBlock
              textArray={[
                { text: t("yellow_block_2_text_1") },
                { text: t("yellow_block_2_text_2"), bold: true },
              ]}
            />
            <MainTitle text={t("main_text_47")} />
            <BlockThree />
            <YellowBlock
              textArray={[
                { text: t("yellow_block_3_text_1") },
                { text: t("yellow_block_3_text_2"), bold: true },
              ]}
            />
          </>
        );
    }
  };

  return (
    <ContentWrap>
      <div className={cls.hero_container}>
        {getViewByCountry()}
        <Peculiarities />
        {/* <Follow /> */}
        {country === allCounties.UA && <Partnership />}
        {/* <News /> */}
        {/* <Download /> */}
      </div>
    </ContentWrap>
  );
};

export default Hero;
