import * as React from "react";
import i18next from "i18next";
import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "react-calendar/dist/Calendar.css";

const DatePickerComponent = ({ dateRange, setDateRange, ...otherProps }) => {
  return (
    <Calendar
      onChange={setDateRange}
      locale={i18next.language.toLowerCase()}
      value={dateRange}
      selectRange={true}
      maxDate={new Date()}
      {...otherProps}
    />
  );
};

export default DatePickerComponent;
