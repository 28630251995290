import React from 'react';
import cls from './table.module.scss';
import { useTranslation } from 'react-i18next';

const Table = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.table_container}>
      <h1 className={cls.main_text}>{t('tariffs')}*</h1>
      <p className={cls.tariffs_name}>{t('tariff_1')}</p>
      <div className={cls.table}>
        <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
          <div className={cls.cell}>{t('header_2')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
          <div className={cls.cell}>48 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>10 {t('km')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
          <div className={cls.cell}>53 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>11 {t('km')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
          <div className={cls.cell}>63 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>12 {t('km')}</div>
        </div>
      </div>
      <p className={cls.tariffs_name}>{t('tariff_2')}</p>
      <div className={cls.table}>
        <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
          <div className={cls.cell}>{t('header_2')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
          <div className={cls.cell}>50 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>11,5 {t('km')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
          <div className={cls.cell}>55 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>12 {t('km')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
          <div className={cls.cell}>65 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>13,5 {t('km')}</div>
        </div>
      </div>
      <p className={cls.tariffs_name}>{t('tariff_3')}</p>
      <div className={cls.table}>
        <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
          <div className={cls.cell}>{t('header_2')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
          <div className={cls.cell}>50 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>16 {t('km')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
          <div className={cls.cell}>55 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>17 {t('km')}</div>
        </div>
        <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
          <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
          <div className={cls.cell}>65 {t('hrn')}</div>
          <div className={cls.cell + ' ' + cls.border_none_right}>18 {t('km')}</div>
        </div>
      </div>
      <p className={cls.footnote}>*{t('footnote')}</p>
    </div>
  );
};

export default Table;
