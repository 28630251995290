import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthPartner from "src/features/auth-partner";
import {
  getPartnerErrors,
  getPartnerIsLoading,
  getPartnerTokens,
  getPartnerDriversList,
  getDocumentPhotos,
} from "./model/partnerSelectors";
import {
  Button,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  fetchDocumentTitlesById,
  fetchDriverInfo,
  fetchInspectionById,
  fetchPartnerDriversList,
} from "./model/partnerAsyncActions";
import { t } from "i18next";
import CustomModal from "src/shared/components/modal";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import CircularLoader from "src/shared/components/loaders/circle-loader";

const Partner = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalOptions, setOpenModalOptions] = useState(false);
  const [isId, setIsId] = useState(null);
  const [isInspectionId, setIsInspectionId] = useState(null);
  const [error, setError] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const tokens = useSelector(getPartnerTokens);
  const isLoading = useSelector(getPartnerIsLoading);
  const errors = useSelector(getPartnerErrors);
  const driversList = useSelector(getPartnerDriversList);
  const documentPhotos = useSelector(getDocumentPhotos);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDrivers = async () => {
      const data = {
        take: 100,
        skip: 0,
      };
      await dispatch(fetchPartnerDriversList(data));
    };

    if (tokens.mainApiAccessToken) {
      fetchDrivers();
    }
  }, [dispatch, tokens.mainApiAccessToken]);

  const handleOpenOptions = (row) => {
    const { id, lastInspectionId } = row;
    setSelectedDriver(row);
    setOpenModalOptions(true);
    setIsId(id);
    setIsInspectionId(lastInspectionId);
  };

  const handleOpenProfile = async () => {
    const data = await dispatch(fetchDriverInfo(isId));
    if (data.meta.requestStatus === "fulfilled") {
      setOpenModalOptions(false);
      navigate("/partner/profile");
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  const handleOpenInspection = async () => {
    const data = await dispatch(fetchInspectionById(isInspectionId));
    if (data.meta.requestStatus === "fulfilled") {
      setOpenModalOptions(false);
      navigate("/partner/inspection");
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  const handleOpenDocuments = async () => {
    const data = await dispatch(fetchDocumentTitlesById(isId));
    if (data.meta.requestStatus === "fulfilled") {
      setOpenModalOptions(false);
      setOpenModal(true);
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  const handleOpenDriverSalary = () => {
    navigate(`/partner-pages/driver-salary/${selectedDriver.id}`);
  };

  if (!tokens.accessToken || !tokens.mainApiAccessToken) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100%"
        mt={5}
      >
        <AuthPartner isLoading={isLoading} errors={errors} />;
      </Grid>
    );
  }

  if (isLoading || !driversList.content) {
    return <CircularLoader />;
  }

  const selectDriverButtons = [
    { text: t("Profile"), onClick: handleOpenProfile },
    { text: t("technical_inspection"), onClick: handleOpenInspection },
    { text: t("Documents"), onClick: handleOpenDocuments },
    { text: t("salary"), onClick: handleOpenDriverSalary },
  ];

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100%"
      mt={5}
    >
      <Typography mb={3} variant="h6">
        {t("total_number_of_drivers")} - {driversList.amount}
      </Typography>
      <CustomModal
        open={openModal}
        title={t("view_photo")}
        onClose={() => setOpenModal(false)}
      >
        <ImageList cols={2}>
          {documentPhotos?.map((item) => (
            <ImageListItem key={item.description}>
              <img src={item.url} alt={item.description} loading="lazy" />
              <ImageListItemBar title={item.description} />
            </ImageListItem>
          ))}
        </ImageList>
      </CustomModal>
      <CustomModal
        open={openModalOptions}
        onClose={() => setOpenModalOptions(false)}
      >
        <div className={styles.options_variatns}>
          {error ? (
            <p>{t("no_data")}</p>
          ) : (
            // popup buttons
            <>
              {selectDriverButtons.map((driverButton, index) => (
                <Button
                  key={`${driverButton.text}_${index}`}
                  onClick={driverButton.onClick}
                >
                  {driverButton.text}
                </Button>
              ))}
            </>
          )}
        </div>
      </CustomModal>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("firstName")}</TableCell>
              <TableCell align="right">{t("lastName")}</TableCell>
              <TableCell align="right">{t("phone_number")}</TableCell>
              <TableCell align="right">{t("country")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {driversList.content?.map((row) => {
              return (
                <TableRow
                  hover
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => handleOpenOptions(row)}
                >
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell align="right">{row.lastName}</TableCell>
                  <TableCell align="right">+{row.phone}</TableCell>
                  <TableCell align="right">{row.country}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default memo(Partner);
